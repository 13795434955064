import React from 'react';

class DashboardServiceTermsJaJP extends React.Component {
  onIframeRef = (node) => {
    if (!node) {
      return;
    }
    node.contentWindow.addEventListener('load', () => {
      node.contentWindow.document.body.style =
        'margin: auto; -moz-transform: scale(2); zoom: 2;';
    });

    this.Iframe = node;
  };

  render() {
    return (
      <iframe
        title="Dashboard Service Terms"
        src="static/serviceTerms/serviceTerms3.html"
        ref={this.onIframeRef}
        width="100%"
        height="100%"
      />
    );
  }
}

export default DashboardServiceTermsJaJP;

import intl from 'react-intl-universal';

export const IssueStatus = {
  NEWTASK: 'NEWTASK',
  DOCTORASSIGNED: 'DOCTORASSIGNED',
  REPORTREADY: 'REPORTREADY',
  SENDBACK: 'SENDBACK',
  DONE: 'DONE',
};

export const IssueStatusForClient = {
  NEWTASK: 'NEWTASK',
  DOCTORASSIGNED: 'INPROGRESS',
  REPORTREADY: 'INPROGRESS',
  SENDBACK: 'SENDBACK',
  DONE: 'DONE',
  INPROGRESS: 'INPROGRESS',
};

export const IssueStatusKenshin = {
  NEWTASK: 'NEWTASK',
  DOCTORASSIGNED: 'DOCTORASSIGNED',
  REPORTREADY: 'REPORTREADY',
  SENDBACK: 'SENDBACK',
  DONE: 'DONE',
};

export const IssueStatusToKey = {
  NEWTASK: 'shared_value_status_new_task',
  DOCTORASSIGNED: 'shared_value_status_doctor_assigned',
  REPORTREADY: 'shared_value_status_report_ready',
  SENDBACK: 'shared_value_status_send_back',
  DONE: 'shared_value_status_done',
};

export const KenshinIssueStatusForClient = {
  NEWTASK: 'NEWTASK',
  DOCTORASSIGNED: 'INPROGRESS',
  REPORTREADY: 'INPROGRESS',
  SENDBACK: 'SENDBACK',
  DONE: 'DONE',
  // INPROGRESS: 'INPROGRESS',
};

export const KenshinIssueSelectableStatus = {
  NEWTASK: ['NEWTASK', 'DOCTORASSIGNED'],
  DOCTORASSIGNED: ['DOCTORASSIGNED', 'REPORTREADY'],
  REPORTREADY: ['REPORTREADY', 'DOCTORASSIGNED', 'SENDBACK'],
  SENDBACK: ['SENDBACK'],
  DONE: ['DONE'],
};

export const KenshinIssueSelectableStatusForClient = {
  NEWTASK: ['NEWTASK'],
  DOCTORASSIGNED: ['DOCTORASSIGNED'],
  REPORTREADY: ['REPORTREADY'],
  SENDBACK: ['SENDBACK', 'DONE'],
  DONE: ['DONE'],
};

/**
 *  For receiver organization side:
 *  Given status key name; get the display string in chosen language;
 *  e.g. status key name: "NEWTASK"  --->  display string in JP: "新規依頼"
 */
export const getIssueStatus = (status) => {
  if (status === IssueStatus.NEWTASK) {
    return intl.get('shared_value_status_new_task');
  } else if (status === IssueStatus.DOCTORASSIGNED) {
    return intl.get('shared_value_status_in_progress');
  } else if (status === IssueStatus.REPORTREADY) {
    return intl.get('shared_value_status_report_ready');
  } else if (status === IssueStatus.SENDBACK) {
    return intl.get('shared_value_status_send_back_receiver');
  } else if (status === IssueStatus.DONE) {
    return intl.get('shared_value_status_done_receiver');
  }
};

/**
 *  For client (sender) organization side:
 *  Given status key name; get the display string in chosen language;
 *  e.g. "DOCTORASSIGNED" / "REPORTREADY" --->  "進行中"
 */
export const getIssueStatusForClient = (status) => {
  if (
    status === IssueStatus.NEWTASK ||
    status === IssueStatusForClient.NEWTASK
  ) {
    return intl.get('shared_value_status_new_task');
  } else if (
    status === IssueStatus.DOCTORASSIGNED ||
    status === IssueStatusForClient.DOCTORASSIGNED
  ) {
    return intl.get('shared_value_status_in_progress');
  } else if (
    status === IssueStatus.REPORTREADY ||
    status === IssueStatusForClient.REPORTREADY
  ) {
    return intl.get('shared_value_status_in_progress');
  } else if (
    status === IssueStatus.SENDBACK ||
    status === IssueStatusForClient.SENDBACK
  ) {
    return intl.get('shared_value_status_send_back_sender');
  } else if (
    status === IssueStatus.DONE ||
    status === IssueStatusForClient.DONE
  ) {
    return intl.get('shared_value_status_done_sender');
  }
};

export const getIssueStatusInFigures = (status) => {
  if (status === IssueStatus.NEWTASK) {
    return '/static/img/progress-1.png';
  } else if (status === IssueStatus.DOCTORASSIGNED) {
    return '/static/img/progress-3.png';
  } else if (status === IssueStatus.REPORTREADY) {
    return '/static/img/progress-4.png';
  } else if (status === IssueStatus.SENDBACK) {
    return '/static/img/progress-5.png';
  } else if (status === IssueStatus.DONE) {
    return '/static/img/progress-6.png';
  } else if (status === IssueStatus.RECHECK) {
    return 'static/img/progress-7.png';
  }
};

export const IssueSelectableStatus_Kenshin_Sender = {
  NEWTASK: [],
  DOCTORASSIGNED: [],
  REPORTREADY: [],
  INPROGRESS: [],
  SENDBACK: ['DONE'],
  DONE: [],
};

export const IssueSelectableStatusReceiver = {
  NEWTASK: ['NEWTASK', 'DOCTORASSIGNED'],
  DOCTORASSIGNED: ['DOCTORASSIGNED', 'REPORTREADY'],
  REPORTREADY: ['REPORTREADY', 'DOCTORASSIGNED', 'SENDBACK'],
  SENDBACK: ['SENDBACK', 'DOCTORASSIGNED', 'REPORTREADY'],
  DONE: ['DONE'],
};

import { makeStyles } from '@material-ui/core';
import { StyleSheet, Font } from '@react-pdf/renderer';

Font.register({
  family: 'NotoSansJP',
  fonts: [
    {
      src: '/static/font/Noto_Sans_JP/NotoSansJP-Black.otf',
      fontWeight: 'black',
    },
    {
      src: '/static/font/Noto_Sans_JP/NotoSansJP-Bold.otf',
      fontWeight: 'bold',
    },
    {
      src: '/static/font/Noto_Sans_JP/NotoSansJP-Light.otf',
      fontWeight: 'light',
    },
    {
      src: '/static/font/Noto_Sans_JP/NotoSansJP-Medium.otf',
      fontWeight: 'medium',
    },
    {
      src: '/static/font/Noto_Sans_JP/NotoSansJP-Regular.otf',
      fontWeight: 'normal',
    },
    {
      src: '/static/font/Noto_Sans_JP/NotoSansJP-Thin.otf',
      fontWeight: 'thin',
    },
  ],
});

Font.register({
  family: 'NotoSansRegular',
  src: '/static/font/NotoSans-Regular.ttf',
});

Font.register({
  family: 'NasuRegular',
  src: '/static/font/Nasu-Regular.ttf',
});

Font.register({
  family: 'Nasu',
  fonts: [
    {
      src: '/static/font/Nasu/Nasu-Regular-20200227.ttf',
      fontWeight: 'normal',
    },
    {
      src: '/static/font/Nasu/Nasu-Bold-20200227.ttf',
      fontWeight: 'bold',
    },
  ],
});

export const useStyles = makeStyles((theme) => ({
  pdfViewer: {
    // maxWidth: '100%',
    // maxHeight: '90%',
    // width: '1000px',
    // height: '1000',
  },
}));

export const styles = StyleSheet.create({
  headerIWG: {
    fontSize: 12,
    textAlign: 'center',
    color: 'grey',
  },
  firstPage: {
    // fontFamily: 'Helvetica',
    fontSize: 10,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  reportTitleContainer: {
    // flexDirection: 'row',
    marginTop: 10,
    // textAlign: 'center',
  },
  reportTitleText: {
    // color: '#61dafb',
    fontFamily: 'Nasu',
    fontWeight: 'bold',
    letterSpacing: 4,
    fontSize: 22,
    textAlign: 'center',
    // textTransform: 'uppercase',
  },
  reportNoContainer: {
    flexDirection: 'row',
    marginTop: 12,
    justifyContent: 'flex-end',
  },
  reportNoLabel: {
    font: 12,
    fontFamily: 'Nasu',
    fontWeight: 'normal',
  },
  reportNoText: {
    font: 30,
    fontFamily: 'Nasu',
    fontWeight: 'normal',
  },
  reportDateContainer: {
    flexDirection: 'row',
    marginTop: 12,
    justifyContent: 'flex-end',
  },
  reportDateLabel: {
    font: 12,
    fontFamily: 'Nasu',
    fontWeight: 'normal',
  },
  reportDateText: {
    font: 14,
    fontFamily: 'Nasu',
    // fontWeight: 'bold',
  },
  reportInfoTable: {
    flexWrap: 'wrap',
    marginTop: 5,
    borderWidth: 1,
    minHeight: 110,
    // boderColor: '#bff0fd',
  },
  reportInfoRow: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 20,
    // flexWrap: 'wrap',
    // fontWeight: 'bold',
    fontFamily: 'Nasu',
    // borderBottomWidth: 1,
    // borderBottomColor: '#bff0fd',
  },
  reportInfoKey: {
    width: '15%',
    padding: 1,
    fontWeight: 'bold',
  },
  reportInfoValue: {
    width: '35%',
    padding: 1,
  },
  reportInfoValue2: {
    width: '85%',
    padding: 1,
  },
  reportSectionTitle: {
    flexDirection: 'row',
    marginTop: 5,
    padding: 5,
    fontWeight: 'bold',
    fontSize: 14,
    fontFamily: 'Nasu',
  },
  reportSectionContent: {
    flexWrap: 'wrap',
    minHeight: '10%',
    marginTop: 2,
    padding: 1,
    borderWidth: 1,
    fontFamily: 'Nasu',
    // borderColor: '#bff0fd',
  },
  reportDateAndDoctors: {
    flexDirection: 'row',
    marginTop: 5,
  },
  reportDate: {
    width: '50%',
    justifyContent: 'flex-end',
    fontFamily: 'Nasu',
  },
  reportDoctors: {
    width: '50%',
    justifyContent: 'center',
    fontFamily: 'Nasu',
  },
  keyImage: {
    margin: 5,
    width: '40%',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

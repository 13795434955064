import styled from 'styled-components';
import { color, font, mixin } from 'shared/utils/styles';

export const Content = styled.div`
  // display: flex;
  padding: 0 6px 30px;
`;

export const Title = styled.div`
  padding: 10px 0 6px;
  ${font.size(18)};
  ${font.medium};
`;

export const Line = styled.div`
  padding: 5px 0 0 10px;
  display: flex;
  width: 100%;
`;

export const Key = styled.div`
  font-weight: bold;
  width: 25%;
  ${font.size(15)};
  ${font.medium};
`;

export const Value = styled.div`
  width: 25%;
  ${font.size(15)};
  ${font.medium};
`;

export const ValueFill = styled.div`
  max-width: 100%;
  ${font.size(15)};
  ${font.medium};
`;

import React, { Fragment, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, TextField } from '@material-ui/core';
import intl from 'react-intl-universal';

import ResultChip from '../../DiagnosticResult/ResultChip';

import { Content, Title, Line, Key, Value, ValueFill } from './styles';
import { addDiagnosis } from '../../api';

const propTypes = {
  assignments: PropTypes.array.isRequired,
  diagnosticNote: PropTypes.any.isRequired,
  setDiagnosticNote: PropTypes.any.isRequired,
  currentIndex: PropTypes.any.isRequired,
  updateLocalData: PropTypes.func.isRequired,
  handleGoToNext: PropTypes.func.isRequired,
};

const ViewerDiagnosis = ({
  assignments,
  currentIndex,
  diagnosticNote,
  setDiagnosticNote,
  updateLocalData,
  handleGoToNext,
}) => {
  const handleNoteChange = (event) => {
    setDiagnosticNote(event.target.value);
  };

  const handleClickSick = async () => {
    await addDiagnosis(
      assignments[currentIndex].id,
      'DIAGNOSED_SICK',
      // latestDiagnosticNote.current
      diagnosticNote
    );
    updateLocalData(currentIndex, {
      diagnosis: 'DIAGNOSED_SICK',
      diagnosticNote: diagnosticNote,
    });
    // setDiagnosticNote('');
    handleGoToNext();
  };

  const handleClickUnknow = async () => {
    await addDiagnosis(
      assignments[currentIndex].id,
      'DIAGNOSED_UNKNOWN',
      // latestDiagnosticNote.current
      diagnosticNote
    );
    updateLocalData(currentIndex, {
      diagnosis: 'DIAGNOSED_UNKNOWN',
      diagnosticNote: diagnosticNote,
    });
    // setDiagnosticNote('');
    handleGoToNext();
  };

  const handleClickHealthy = async () => {
    await addDiagnosis(
      assignments[currentIndex].id,
      'DIAGNOSED_HEALTHY',
      // latestDiagnosticNote.current
      diagnosticNote
    );
    updateLocalData(currentIndex, {
      diagnosis: 'DIAGNOSED_HEALTHY',
      diagnosticNote: diagnosticNote,
    });
    // setDiagnosticNote('');
    handleGoToNext();
  };

  // Keyboard shortcut
  const handleKeyPress = useCallback(async (e) => {
    const { code, ctrlKey, altKey, shiftKey } = e;
    if (shiftKey && e.key === 'Enter') {
      handleClickHealthy();
    }
  }, []);

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Fragment>
      <Content>
        <Title>Diagnosis</Title>
        <ResultChip result={assignments[currentIndex].diagnosis} />
        <Line>
          <ValueFill>Note</ValueFill>
        </Line>
        <Line>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            value={diagnosticNote}
            onChange={handleNoteChange}
          />
        </Line>
        <Line />
        <Line />
        <Line>
          <Grid container>
            <Grid container item xs={4} justifyContent="center">
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClickSick}>
                {intl.get('kenshin_case_diagnostic_result_diagnosed_sick')}
              </Button>
            </Grid>
            <Grid
              container
              item
              xs={4}
              justifyContent="center"
              onClick={handleClickUnknow}>
              <Button variant="contained" color="warning">
                {intl.get('kenshin_case_diagnostic_result_diagnosed_unknown')}
              </Button>
            </Grid>
            <Grid container item xs={4} justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleClickHealthy}>
                {intl.get('kenshin_case_diagnostic_result_diagnosed_healthy')}
              </Button>
            </Grid>
          </Grid>
        </Line>
      </Content>
    </Fragment>
  );
};

ViewerDiagnosis.propTypes = propTypes;

export default ViewerDiagnosis;

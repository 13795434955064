import React from 'react';
class DashboardManualJaJP extends React.Component {
  onIframeRef = (node) => {
    if (!node) {
      return;
    }
    node.contentWindow.addEventListener('load', () => {
      node.contentWindow.document.body.style =
        'margin: auto; -moz-transform: scale(2); zoom: 2;';
    });

    this.Iframe = node;
  };

  render() {
    return (
      <iframe
        title="Dashboard Manual"
        // src="static/manual/DoCloud_1.0.0.6.html"
        src="static/manual/DoCloud_1.0.0.7/DoCloud™操作マニュアル 7daaf275768f4244b2feec03d563be66.html"
        ref={this.onIframeRef}
        width="100%"
        height="100%"
        frameBorder={0}
      />
    );
  }
}

export default DashboardManualJaJP;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Content, Title, Line, Key, Value } from './styles';

const propTypes = {
  case_: PropTypes.object.isRequired,
};

const ViewerInfo = ({ case_ }) => {
  return (
    <Fragment>
      <Content>
        <Title>Case Info</Title>
        <Line>
          <Key>Case ID: </Key>
          <Value>{case_.caseIdentifierId}</Value>
        </Line>
        <Line>
          <Key>Patient ID: </Key>
          <Value>{case_.patientId}</Value>
          <Key>Study ID</Key>
          <Value>{case_.studyInstanceUID}</Value>
        </Line>
        <Line>
          <Key>Patient Name: </Key>
          <Value>{case_.patientName}</Value>
          <Key>From: </Key>
          <Value>{case_.srcOrgName}</Value>
        </Line>
        <Line>
          <Key>Patient Gender: </Key>
          <Value>{case_.patientGender}</Value>
          <Key>Patient Age:</Key>
          <Value>{case_.patientAge}</Value>
        </Line>
        <Line>
          <Key>Body Part:</Key>
          <Value>{case_.bodypart}</Value>
          <Key>Modality:</Key>
          <Value>{case_.modality}</Value>
        </Line>
      </Content>
    </Fragment>
  );
};

ViewerInfo.propTypes = propTypes;

export default ViewerInfo;
